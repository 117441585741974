import Routes from 'routes/Routes';
import { configureFakeBackend } from 'data';

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';

import * as Sentry from '@sentry/react';
if (process.env.REACT_APP_SENTRY_DSN)
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            new RegExp(process.env.REACT_APP_URL?.replaceAll('/', '\\/')?.replaceAll('.', '\\.') ?? 'UNDEFINED'),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

const App = () => {
    console.log(process.env.REACT_APP_URL?.replaceAll('/', '\\/')?.replaceAll('.', '\\.'));
    if (!process.env.REACT_APP_API_URL) configureFakeBackend();
    return <Routes />;
};

export default App;
