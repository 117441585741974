import { isTemplateExpression } from 'typescript';
import { CartActionType, CartActionTypes, State } from './constants';
import { ShopCart } from 'data/types';


const INIT_STATE: State<ShopCart> = {
    data: {
        items: [],
        selected: []
    },
    loading: false,
};

const Cart = (state: State<ShopCart> = INIT_STATE, action: CartActionType) => {
    switch (action.type) {
        case CartActionTypes.SELECT_PRODUCT:
            if (!action.payload.id) return state;
            return {
                ...state,
                data: {
                    ...state.data,
                    selected: [...(state.data?.selected ?? []), ...[action.payload.id]],
                },
                loading: false,
            };
        case CartActionTypes.SELECT_ALL_PRODUCT:
            return {
                ...state,
                data: {
                    ...state.data,
                    selected: state.data?.items?.map(item => item.product.id),
                },
                loading: false,
            };
        case CartActionTypes.UNSELECT_PRODUCT:
            console.log(action.payload);
            if (!action.payload.id) return state;
            return {
                ...state,
                data: {
                    ...state.data,
                    selected: state.data?.selected?.filter((item) => item !== action.payload.id) || [],
                },
                loading: false,
            };
        case CartActionTypes.RESET_SELECT:
            return {
                ...state,
                data: {
                    ...state.data,
                    selected: [],
                },
                loading: false,
            };
        case CartActionTypes.ADD_PRODUCT:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...(state.data?.items ?? []), ...[action.payload.data]],
                },
                loading: false,
            };
        case CartActionTypes.EDIT_PRODUCT:
            if (!action.payload.data) return state;
            return {
                ...state,
                data: {
                    items: state.data?.items?.map((item, index) =>
                        item.product.id === action.payload.id && action.payload.data
                            ? { ...state.data?.items?.[index], ...action.payload.data }
                            : item
                    ),
                },
                loading: false,
            };

        case CartActionTypes.DELETE_PRODUCT: {
            if (!action.payload.id) return state;
            return {
                ...state,
                data: {
                    items: state.data?.items?.filter((item) => item.product.id !== action.payload.id),
                },
                loading: false,
            };
        }

        case CartActionTypes.DELETE_MANY_PRODUCTS: {
            if (!action.payload.ids) return state;
            return {
                ...state,
                data: {
                    items: state.data?.items?.filter((item) => item.product.id && !action.payload.ids?.includes(item.product.id) ),
                },
                loading: false,
            };
        }
        case CartActionTypes.RESET:
            return {
                ...state,
                data: [],
                loading: false,
            };

        default:
            return { ...state };
    }
};

export default Cart;
