import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'data/api/apiCore';
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    getUser as refreshUserApi,
} from 'data';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import jwtDecode from 'jwt-decode';
import { UserRepository } from 'data/api/user';
import { ApiResponse, Session, User } from 'data/types';

type UserData = {
    payload: {
        username: string;
        password: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        email: string;
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
    try {
        const response: ApiResponse<Session> = yield call(loginApi, { email: username, password });
        if (!response) throw new Error('login_error');
        if (response.status === 401) throw new Error('login_user_not_found');
        if (response.status === 403) throw new Error('login_forbidden');
        if (!response || !response.data) throw new Error('login_user_not_found');
        if (response.data?.details) {
            const msg = (Object.values(response.data?.details ?? {})?.[0] as any)?.[0];
            if (msg) throw new Error(msg);
        }
        if (response.data?.error) throw new Error(response.data?.error);
        if (response?.data?.has_deactivated_store) throw new Error('login_user_without_store');
        // if (!response?.data?.store?.agorachei_pro && !response?.data?.store?.franchise) throw new Error('login_store_not_PRO');
        const user = response.data;
        if (!user.token) throw new Error('login_without_session_token');

        console.log({ user });
        api.setLoggedInUser(user);
        setAuthorization(user.token);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error: any) {
        console.log({ error });
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error?.message || "Erro, tente novamente mais tarde."));
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        console.log('CAIU LOGOUT !!!!!!');
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error?.message || "Erro, tente novamente mais tarde."));
    }
}

function* signup({ payload }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, payload);
        console.log(response);
        if (!response) throw new Error('register_error');
        if (response.status === 401) throw new Error('login_user_not_found');
        if (response.status === 403) throw new Error('register_forbidden');
        if (response.data?.details) {
            console.log(response.data?.details);
            console.log(Object.values(response.data?.details ?? {}));
            const msg = (Object.values(response.data?.details ?? {})?.[0] as any)?.[0];
            console.log(msg);
            if (msg) throw new Error(msg);
        }
        if (response.data?.error) throw new Error(response.data?.error);
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, { ...payload, ...user }));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error.message));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { email: username });
        if (response.status === 400) throw new Error(response?.data?.error || response?.data?.message || 'forgot_password_error');
        if (response.status === 401) throw new Error(response?.data?.error || response?.data?.message || 'login_user_not_found');
        if (response.status === 404) throw new Error(response?.data?.error || response?.data?.message || 'login_user_not_found');
        if (response.status === 403) throw new Error(response?.data?.error || response?.data?.message || 'login_forbidden');
        if (response.data?.details) {
            const msg = (Object.values(response.data?.details ?? {})?.[0] as any)?.[0];
            if (msg) throw new Error(msg);
        }
        if (response.data?.error) throw new Error(response.data?.error);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error.message));
    }
}

/**
 * Refresh the user
 */
function* refreshUser(): SagaIterator {
    try {
        const user = api.getLoggedInUser();
        if (!user) return yield call(logout);
        const response: ApiResponse<User> = yield call(refreshUserApi as any, { id: user?.id });
        if (!response) throw new Error('refresh_user_error');
        if (response.status === 401) return yield call(logout);
        if (response.status === 403) throw new Error('refresh_user_permission_error');
        if (!response.data) throw new Error('refresh_user_error');
        if (response?.data?.error) throw new Error(response?.data?.error);
        if (response?.data?.details) throw new Error(response?.data?.details);
        if (response?.data?.has_deactivated_store) return yield call(logout);
        // if (!response?.data?.store?.agorachei_pro) return yield call(logout);
        const newUser = { ...user, ...response.data };
        console.log({ newUser });

        api.setLoggedInUser(newUser);
        yield put(authApiResponseSuccess(AuthActionTypes.REFRESH_USER, newUser));
    } catch (error: any) {
        console.log({ error });
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseError(AuthActionTypes.REFRESH_USER, error?.message || "Erro, tente novamente mais tarde."));
    }
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchRefreshUser() {
    yield takeEvery(AuthActionTypes.REFRESH_USER, refreshUser);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchRefreshUser),
    ]);
}

export default authSaga;
